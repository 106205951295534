export default [
  {
    header: 'Konfiguracja',
  },
  {
    title: 'Podstawowe dane',
    route: 'general-information',
    icon: 'SlidersIcon',
  },
  // TODO: removed for client test
  // {
  //   title: 'Kategorie',
  //   route: 'categories',
  //   icon: 'BookmarkIcon',
  // },
  {
    header: 'Sprzedaż',
  },
  {
    title: 'Zamówienia',
    route: 'orders-index',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Metody dostawy',
    route: 'delivery-methods-index',
    icon: 'TruckIcon',
  },
  {
    title: 'Kody rabatowe',
    route: 'discount-codes-index',
    icon: 'GiftIcon',
  },
  {
    header: 'Content',
  },
  {
    title: 'Nawigacja',
    route: 'navigation-index',
    icon: 'MenuIcon',
  },
  {
    title: 'Strony statyczne',
    icon: 'FileIcon',
    children: [
      {
        title: 'O Nas',
        route: 'about-us',
      },
      {
        title: 'Ogólne Warunki Sprzedaży',
        route: 'general-terms-of-sale',
      },
      {
        title: 'Polityka prywatności',
        route: 'privacy-policy',
      },
      {
        title: 'Regulamin',
        route: 'statute',
      },
      {
        title: 'Komunikacja z akcjonariuszami',
        route: 'shareholders-communication',
      },
      {
        title: 'Dla designerów',
        route: 'for-designers-page',
      },
    ],
  },
  {
    title: 'Elementy statyczne',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Karuzela na stronie głównej',
        route: 'hero-section-carousel',
      },
      {
        title: 'Invena w liczbach',
        route: 'invena-in-numbers',
      },
      {
        title: 'Trwanie gwarancji',
        route: 'guarantee-details',
      },
      {
        title: 'O nas - na stronie głównej',
        route: 'about-us-homepage',
      },
      {
        title: 'O nas - opinie',
        route: 'about-us-reviews-index',
      },
      {
        title: 'Metody dostawy - informacje dodatkowe',
        route: 'delivery-methods-additional-information',
      },
      {
        title: 'Opis kategorii',
        route: 'categories-description',
      },
      {
        title: 'Dla designerów',
        route: 'for-designers',
      },
    ],
  },
  {
    title: 'Pliki do pobrania',
    icon: 'FilePlusIcon',
    children: [
      {
        title: 'Pliki do pobrania',
        route: 'downloadable-files-index',
      },
      {
        title: 'Kategorie',
        route: 'downloadable-files-tags-index',
      },
    ],
  },
  {
    title: 'SEO',
    icon: 'LayersIcon',
    route: 'seo-index',
  },
  {
    header: 'Blog',
  },
  {
    title: 'Posty',
    icon: 'BookOpenIcon',
    route: 'blog-posts-index',
  },
  {
    title: 'Tagi',
    icon: 'TagIcon',
    route: 'blog-categories-index',
  },
  {
    header: 'Inspiracje',
  },
  {
    title: 'Inspiracje - lista',
    icon: 'LoaderIcon',
    route: 'inspirations-index',
  },
  {
    title: 'Inspiracje - strona',
    icon: 'FileIcon',
    route: 'inspirations-page-index',
  },
  {
    header: 'Inne',
  },
  {
    title: 'Oferty pracy',
    icon: 'UserPlusIcon',
    route: 'job-offers-index',
  },
  {
    title: 'Przedstawiciele handlowi',
    route: 'sale-representatives-index',
    icon: 'UsersIcon',
  },
  {
    title: 'Przedstawiciele zagraniczni',
    route: 'wholesale-representatives-index',
    icon: 'UsersIcon',
  },
  {
    title: 'Serwisanci',
    route: 'technicians-index',
    icon: 'ToolIcon',
  },
  {
    title: 'Zgłoszenia współpracy',
    icon: 'BellIcon',
    route: 'cooperation-index',
  },
];
